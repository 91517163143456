<template>
  <div class="text-center">
    <v-dialog v-model="visible" width="500">
      <v-card elevation="0">
        <v-card-title class="text-h5 grey lighten-2">
          Shipment Record Unsaved
        </v-card-title>

        <v-card-text>
          To continue, you must save the shipment first.
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="onClose">
            Go back and save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      visible: this.dialog
    };
  },
  watch: {
    dialog(val) {
      this.visible = val;
    }
  },
  methods: {
    onClose() {
      this.$emit('close');
    }
  }
};
</script>
